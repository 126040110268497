//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import { Chart } from "@antv/g2";
import MixinTeamOptions from "@/mixins/teamOptions";
import MixinWebrtc from "@/mixins/webrtcNew";
import Request from "@/webrtc/server/internal/Request";
export default {
  name: "RealTimeLoad",
  mixins: [MixinTeamOptions, MixinWebrtc],
  components: {
    Header: () => import("@/components/Header"),
    Statistic: () => import("@/components/Statistic"),
  },
  data() {
    return {
      functionName: "实时负载",
      NeedDataChannel: true,
      loading: false,
      dataLoading: false,
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      members: [], // loaded member list data
      memberLoading: false,
      activeMember: {},
      timer: null,
      cpuChart: null,
      memoryChart: null,
      flowUploadChart: null,
      flowDownloadChart: null,
      currentData: {
        cpu: { cpuUsage: 0, cpuCurrentClockSpeed: 0, cpuMaxClockSpeed: 0 },
        memory: { memoryUsage: 0, memoryTotal: 0, memoryUsageTotal: 0 },
        network: { networkSent: 0, networkRecieved: 0 },
      },
      cpuData: [],
      memoryData: [],
      networkData: [],
      flowActive: "upload",
    };
  },
  mounted() {
    // if (this.selMem.length > 0) {
    //   this.activeMember = {
    //     UserId: this.selMem[0].UsId,
    //     Identity: this.selMem[0].Identity,
    //   };
    // }
    this.initCpu();
    this.initMemory();
    this.initFlowUpload();
    this.initFlowDownload();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  watch: {
    teamValue() {
      this.queryMembers();
    },
    activeMember() {
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (!this.activeMember) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }

      if (this.teamValue) {
        const team = this.teamOptions.find((m) => m.Id == this.teamValue);
        if (team && !team.IsMonitoringPerformance) {
          this.$message({
            type: "error",
            message: "团队未开启实时负载监测",
          });
          return;
        }
      }
      this.startTimer();
    },
  },
  methods: {
    async sendWebrtcRequestNoIdentity(request) {
      var identity = this.activeMember.Identity;
      await this.Call(identity);
      var b = this.SendRequest(null, identity, request);
      if (!b) {
        throw "发送请求失败";
      }
    },
    /**
     * 团队切换
     */
    handleTeamChange(v) {
      this.teamValue = v;
    },
    async queryMembers() {
      this.memberLoading = true;
      const { res, data } = await this.$http.post(
        "/Management/TeamManagement/MenberSelects.ashx",
        {
          teamId: this.teamValue,
        }
      );

      if (res === 0) {
        this.members = data;
        if (this.activeMember) {
          var user = this.members.find(
            (m) => m.UserId == this.activeMember.UserId
          );
          if (user) {
            this.activeMember = user;
          }
        }
      }
      this.memberLoading = false;
    },
    async startTimer() {
      this.cpuData.splice(0, this.cpuData.length);
      this.memoryData.splice(0, this.memoryData.length);
      this.networkData.splice(0, this.networkData.length);
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.loading = true;
      this.HangUpAll();
      var identity = this.activeMember.Identity;
      try {
        await this.Call(identity);
        this.timer = setInterval(this.getData, 1000);
      } catch (error) {
        this.$message({
          type: "error",
          message: error,
        });
        this.loading = false;
      }
    },
    changeFlowTab(tabName) {
      this.flowActive = tabName;
      // 下面代码解决第一次渲染，图标宽度过款问题
      const e = document.createEvent("Event");
      e.initEvent("resize", true, true);
      window.dispatchEvent(e);
    },
    getData() {
      if (this.dataLoading) {
        return;
      }
      this.dataLoading = true;
      var self = this;
      Promise.all([this.getCpu(), this.getMemory(), this.getNetwork()]).then(
        (resp) => {
          if (resp[0].State) {
            resp[0].Data.cpuUsage = resp[0].Data.cpuUsage.toFixed(2) * 1;
            resp[0].Data.cpuCurrentClockSpeed =
              (resp[0].Data.cpuCurrentClockSpeed / 1000).toFixed(2) * 1;
            resp[0].Data.cpuMaxClockSpeed =
              (resp[0].Data.cpuMaxClockSpeed / 1000).toFixed(2) * 1;
            self.currentData.cpu.cpuUsage = resp[0].Data.cpuUsage;
            self.currentData.cpu.cpuCurrentClockSpeed =
              resp[0].Data.cpuCurrentClockSpeed;
            self.currentData.cpu.cpuMaxClockSpeed =
              resp[0].Data.cpuMaxClockSpeed;
            let data = resp[0].Data;
            if (self.cpuData.length >= 1000) {
              self.cpuData.shift();
            }
            self.cpuData.push(data);
            data.index = self.cpuData.length;
            data.time = new Date().timeFormat("HH:mm:ss");
            this.cpuChart.changeData(self.cpuData);
          }
          if (resp[1].State) {
            resp[1].Data.memoryTotal =
              (resp[1].Data.memoryTotal / 1024 / 1024).toFixed(2) * 1;
            resp[1].Data.memoryUsage = resp[1].Data.memoryUsage.toFixed(2) * 1;
            self.currentData.memory.memoryTotal = resp[1].Data.memoryTotal;
            self.currentData.memory.memoryUsage = resp[1].Data.memoryUsage;
            var memoryUsageTotal =
              resp[1].Data.memoryTotal * (resp[1].Data.memoryUsage / 100);
            self.currentData.memory.memoryUsageTotal =
              memoryUsageTotal.toFixed(2) * 1;
            let data = resp[1].Data;
            if (self.memoryData.length >= 1000) {
              self.memoryData.shift();
            }
            self.memoryData.push(data);
            data.index = self.memoryData.length;
            data.time = new Date().timeFormat("HH:mm:ss");
            this.memoryChart.changeData(self.memoryData);
          }
          if (resp[2].State) {
            resp[2].Data.networkSent =
              (resp[2].Data.networkSent / 1024).toFixed(2) * 1;
            resp[2].Data.networkRecieved =
              (resp[2].Data.networkRecieved / 1024).toFixed(2) * 1;
            self.currentData.network.networkSent = resp[2].Data.networkSent;
            self.currentData.network.networkRecieved =
              resp[2].Data.networkRecieved;
            let data = resp[2].Data;
            if (self.networkData.length >= 1000) {
              self.networkData.shift();
            }
            self.networkData.push(data);
            data.index = self.networkData.length;
            data.time = new Date().timeFormat("HH:mm:ss");
            this.flowUploadChart.changeData(self.networkData);
            this.flowDownloadChart.changeData(self.networkData);
          }
        }
      );
      self.dataLoading = false;
      self.loading = false;
    },
    getCpu() {
      var promise = new Promise((resolve, reject) => {
        var request = new Request();
        request.Url = "/Performance/CPU";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.OnError = function (error) {
          reject(error);
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (!result.State) {
            reject(result.Message);
          } else {
            resolve(result);
          }
        };
        this.sendWebrtcRequestNoIdentity(request).catch((error) => {
          reject(error);
        });
      });
      return promise;
    },
    getMemory() {
      var promise = new Promise((resolve, reject) => {
        var request = new Request();
        request.Url = "/Performance/Memory";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.OnError = function (error) {
          reject(error);
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (!result.State) {
            reject(result.Message);
          } else {
            resolve(result);
          }
        };
        this.sendWebrtcRequestNoIdentity(request).catch((error) => {
          reject(error);
        });
      });
      return promise;
    },
    getNetwork() {
      var promise = new Promise((resolve, reject) => {
        var request = new Request();
        request.Url = "/Performance/Network";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.OnError = function (error) {
          reject(error);
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (!result.State) {
            reject(result.Message);
          } else {
            resolve(result);
          }
        };
        this.sendWebrtcRequestNoIdentity(request).catch((error) => {
          reject(error);
        });
      });
      return promise;
    },
    initCpu() {
      const chart = new Chart({
        container: this.$refs.cpu,
        autoFit: true,
        // height: 500,
        theme: {
          styleSheet: {
            brandColor: "#81b7d7",
          },
        },
      });

      chart.data(this.cpuData);
      chart.scale({
        time: {
          nice: true,
          alias: "时间",
        },
        cpuUsage: {
          nice: true,
          alias: "使用量",
        },
      });
      // chart.tooltip({
      //   showCrosshairs: true,
      //   shared: true,
      // });

      chart.axis("time", {
        label: false,
      });
      chart.axis("cpuUsage", {
        label: false,
      });

      chart.area().position("time*cpuUsage");
      chart.line().position("time*cpuUsage");
      // chart.option('scrollbar', {
      //   type: 'horizontal',
      //   animate: true,
      // })
      chart.render();
      this.cpuChart = chart;
    },
    initMemory() {
      const chart = new Chart({
        container: this.$refs.memory,
        autoFit: true,
        // height: 500,
        theme: {
          styleSheet: {
            brandColor: "#e1bce7",
          },
        },
      });

      chart.data(this.memoryData);
      chart.scale({
        memoryUsage: {
          nice: true,
          alias: "使用量",
        },
        time: {
          nice: true,
          alias: "时间",
        },
      });
      // chart.tooltip({
      //   showCrosshairs: true,
      //   shared: true,
      // });

      chart.axis("time", {
        label: false,
      });
      chart.axis("memoryUsage", {
        label: false,
      });

      chart.area().position("time*memoryUsage");
      chart.line().position("time*memoryUsage");
      chart.render();
      this.memoryChart = chart;
    },
    initFlowUpload() {
      const chart = new Chart({
        container: this.$refs.flowUpload,
        autoFit: true,
        // height: 500,
        theme: {
          styleSheet: {
            brandColor: "#c8e7bc",
          },
        },
      });

      chart.data(this.networkData);
      chart.scale({
        networkSent: {
          nice: true,
          alias: "上传",
        },
        time: {
          nice: true,
          alias: "时间",
        },
      });
      // chart.tooltip({
      //   showCrosshairs: true,
      //   shared: true,
      // });

      chart.axis("time", {
        label: false,
      });
      chart.axis("networkSent", {
        label: false,
      });

      chart.area().position("time*networkSent");
      chart.line().position("time*networkSent");
      chart.render();
      this.flowUploadChart = chart;
    },
    initFlowDownload() {
      const chart = new Chart({
        container: this.$refs.flowDownload,
        autoFit: true,
        // height: 500,
        theme: {
          styleSheet: {
            brandColor: "#c8e7bc",
          },
        },
      });

      chart.data(this.networkData);
      chart.scale({
        networkRecieved: {
          nice: true,
          alias: "下载",
        },
        time: {
          nice: true,
          alias: "时间",
        },
      });
      // chart.tooltip({
      //   showCrosshairs: true,
      //   shared: true,
      // });

      chart.axis("time", {
        label: false,
      });
      chart.axis("networkRecieved", {
        label: false,
      });

      chart.area().position("time*networkRecieved");
      chart.line().position("time*networkRecieved");
      chart.render();
      this.flowDownloadChart = chart;
    },
  },
  beforeDestroy() {
    this.HangUpAll();
  },
};
